import React from "react";
import cx from "classnames";
import { Switch, Route } from "react-router-dom";

import styles from "./Auth.module.css";

import SignIn from "./SignIn";
import PasswordRecovery from "./RecoveryPassword";
import CodeConfirmation from "./CodeConfirmation";
import ChangePassword from "./ChangePassword";
import RecoverySuccess from "./RecoverySuccess";

import { Typography } from "components/Typography";

const Auth = () => (
  <div className={styles.auth_page}>
    <div className={cx(styles.side, styles.left_side)}>
      <div className={styles.form_container}>
        <Switch>
          <Route path="/login/recovery_success" component={RecoverySuccess} />
          <Route path="/login/change" component={ChangePassword} />
          <Route path="/login/code" component={CodeConfirmation} />
          <Route path="/login/recovery" component={PasswordRecovery} />
          <Route path="/login" component={SignIn} />
        </Switch>
      </div>
    </div>
    <div className={cx(styles.side, styles.photo)}>
      <div className={styles.logo_container}>
        <img alt="Company logo" src="/img/logo1.png" width="297" height="90" />
        <div style={{ height: 96 }} />
        <Typography color="contrast" variant="h3">
          OpCheck
        </Typography>
        <Typography noWrap color="contrast" variant="h5">
          Web management site
        </Typography>
      </div>
    </div>
  </div>
);

export default Auth;

import React from "react";
import { Switch, Route } from "react-router-dom";

import Page from "app/Page";

import View from "./view";
import Edit from "./edit";

import CodeConfirmation from "./password_change/code_confirmation";
import ChangePassword from "./password_change/change_password";
import ChangePasswordSuccess from "./password_change/change_password_success";

const Profile = () => (
  <Page>
    <Switch>
      <Route path="/profile/change_password" component={ChangePassword} />
      <Route path="/profile/code_confirmation" component={CodeConfirmation} />
      <Route
        path="/profile/change_password_success"
        component={ChangePasswordSuccess}
      />
      <Route path="/profile/edit" component={Edit} />
      <Route path="/profile" component={View} />
    </Switch>
  </Page>
);

export default Profile;

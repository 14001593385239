import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Page from "app/Page";

import View from "./view";
import Edit from "./edit";
import Permission from "containers/Permission";

const Profile = () => (
  <Page>
    <Permission required={["manager"]} fallback={<Redirect to="/" />}>
      <Switch>
        <Route path="/company/edit" component={Edit} />
        <Route path="/company" component={View} />
      </Switch>
    </Permission>
  </Page>
);

export default Profile;

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import useAxios from "axios-hooks";
import useForm from "react-hook-form";

import { Loader, LoaderWrapper } from "components";
import { Paper, Toolbar, Separator } from "containers";

import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { Form } from "components/Form";
import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import { NavLink } from "react-router-dom";

import CustomerEditForm, { newUserId, world } from "./form";
import DeleteCustomer from "./delete";
import Content from "containers/Content";
import { createUser } from "app/users/new";
import { editUser } from "app/users/edit/edit";

export async function setPrimaryUser(customer, primary_user) {
  const { selected, ...otherUser } = primary_user;

  if (selected?.id) {
    let user =
      // eslint-disable-next-line eqeqeq
      selected.id == newUserId
        ? await createUser({
            ...otherUser,
            groups: { value: "manager" },
            customer_account: customer
          })
        : await editUser(selected.id, {
            ...otherUser,
            groups: { value: "manager" },
            customer_account: customer
          });

    if (user)
      await api.patch(`/customers/${customer.id}/`, {
        primary_user: user.data.id
      });
  }
}

const EditCustomer = connect(null, dispatch => ({
  push: location => dispatch(push(location))
}))(({ data, push }) => {
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: {
      ...data,
      primary_user: {
        selected: data.primary_user,
        ...data.primary_user
      },
      country: world.find(x => x.alpha3 === data.country)
    }
  });

  const onSubmit = async ({
    primary_user,
    country,
    address_line_two,
    ...other
  }) => {
    try {
      const newCustomer = {
        country: country.alpha3,
        primary_user: null,
        ...other
      };
      if (address_line_two) newCustomer.address_line_two = address_line_two;

      const customer = await api.patch(`/customers/${data.id}/`, newCustomer);

      await setPrimaryUser(customer.data, primary_user);

      push(`/customers/${customer.data.id}/`);
    } catch (error) {
      errorToForm(error).forEach(x => setError(...x));
    }
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to={`/customers/${data.id}`}>
          <BackIcon />
        </IconButton>
        <Typography variant="h5">{data?.company_name} editing</Typography>
      </Toolbar>
      <Paper>
        <Content>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomerEditForm
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <Toolbar margin={false}>
              <Separator />
              <DeleteCustomer customerId={data.id} />
              <Button disabled={isSubmitting} primary type="submit">
                {isSubmitting ? <Loader /> : "Save"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
});

export default props => {
  const [{ data, loading }] = useAxios(`/customers/${props.match.params.id}/`, {
    useCache: false
  });

  return loading ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <EditCustomer data={data} {...props} />
  );
};

import { connect } from "react-redux";

import { selectors } from "store/auth";

const Permission = ({ me, required = [], children, fallback = null }) =>
  required.some(x => me?.groups.includes(x)) ? children : fallback;

export default connect(state => ({
  me: selectors.me(state)
}))(Permission);

import React, { Fragment } from "react";
import useAxios from "axios-hooks";

import { Loader } from "components";
import { Toolbar, Paper } from "containers";
import { IconButton, Fab } from "components/Button";
import { Typography } from "components/Typography";
import { NavLink } from "react-router-dom";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { ReactComponent as EditIcon } from "static/icons/edit.svg";

import CustomerView from "./form";
import Content from "containers/Content";

export const ViewCustomer = ({ match }) => {
  const [{ data, loading }] = useAxios(`/customers/${match.params.id}/`, {
    useCache: false
  });

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/customers">
          <BackIcon />
        </IconButton>
        <Typography variant="h5" style={{ display: "flex", flex: 1 }}>
          {data?.company_name}
        </Typography>
      </Toolbar>
      <Paper>
        <Content>
          {loading || data == null ? (
            <Loader />
          ) : (
            <CustomerView customer={data}></CustomerView>
          )}
        </Content>
        <Fab component={NavLink} to={`/customers/${match.params.id}/edit`}>
          <EditIcon />
        </Fab>
      </Paper>
    </Fragment>
  );
};

export default ViewCustomer;

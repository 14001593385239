import React, { Fragment } from "react";
import useAxios from "axios-hooks";
import { NavLink } from "react-router-dom";

import { useTableData } from "core/apiUtils";

import NavBar from "app/navigation/NavBar";
import SearchField from "components/TextField/SearchField";
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell,
  TableLoader,
  TablePagination,
  TableRow,
  TableCell
} from "components/Table";
import { Fab } from "components/Button";
import { Separator, Toolbar } from "containers";

import { ReactComponent as AddIcon } from "static/icons/add.svg";

const CustomersList = () => {
  const [{ data, loading, response }, refresh] = useAxios(
    {
      url: `/customers/`,
      params: { limit: 25 }
    },
    { useCache: false }
  );

  const [
    limit,
    count,
    offset,
    items,
    handleLimit,
    handlePagination,
    handleSearch
  ] = useTableData(data, response, refresh);

  return (
    <Fragment>
      <Toolbar>
        <NavBar />
        <Separator />
        <SearchField onSearchChange={handleSearch("company_name")} />
      </Toolbar>
      <Table
        fab={
          <Fab component={NavLink} to="/customers/new">
            <AddIcon />
          </Fab>
        }
      >
        <TableHeader>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Primary user</TableHeaderCell>
          <TableHeaderCell>Email</TableHeaderCell>
          <TableHeaderCell>Assigned users</TableHeaderCell>
          <TableHeaderCell>ID</TableHeaderCell>
        </TableHeader>
        <TableBody>
          {loading ? (
            <TableLoader />
          ) : (
            items.map((customer, index) => (
              <TableRow key={index} to={`/customers/${customer.id}`}>
                <TableCell>{customer.company_name}</TableCell>
                <TableCell>
                  {customer.primary_user?.first_name}{" "}
                  {customer.primary_user?.last_name}
                </TableCell>
                <TableCell>{customer.primary_user?.email}</TableCell>
                <TableCell>{customer.assigned_users_number}</TableCell>
                <TableCell>{customer.id}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      <TablePagination
        limit={limit}
        count={count}
        offset={offset}
        onLimitChange={handleLimit}
        onPaginationChange={handlePagination}
      />
    </Fragment>
  );
};

export default CustomersList;

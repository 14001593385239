import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Paper, Toolbar, Separator } from "containers";

import { selectors, actions } from "store/auth";
import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import useForm from "react-hook-form";
import api from "core/axios";
import { errorToForm } from "core/apiUtils";
import UserEditForm, { roles } from "app/users/edit/form";
import { Form } from "components/Form";
import { Loader } from "components";
import { push } from "connected-react-router";
import Content from "containers/Content";

export const ProfileEdit = ({ me, fetchMeSuccess, push }) => {
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: {
      ...me,
      groups: roles.find(x => me.groups.includes(x.value))
    }
  });

  const onSubmit = ({ groups, ...other }) => {
    return api
      .patch(`/me/`, {
        groups: [groups.value],
        ...other
      })
      .then(({ data }) => {
        fetchMeSuccess(data);
        push("/profile");
      })
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/profile">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">Profile editing</Typography>
      </Toolbar>
      <Paper>
        <Content>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <UserEditForm
              me={me}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
            <Toolbar margin={false}>
              <Button component={NavLink} to="/profile/code_confirmation">
                Change password
              </Button>
              <Separator />
              <Button disabled={isSubmitting} primary>
                {isSubmitting ? <Loader /> : "Save"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default connect(
  state => ({
    me: selectors.me(state)
  }),
  dispatch => ({
    fetchMeSuccess: data => dispatch(actions.fetchMeSuccess(data)),
    push: location => dispatch(push(location))
  })
)(ProfileEdit);

import axios from "axios";
import { configure } from "axios-hooks";

import { store } from "../index";
import { actions } from "store/auth";

const ciApi = process.env.REACT_APP_URL;
const baseURL = ciApi
  ? ciApi
  : "https://staging-opcheck.safeguardanalytics.com/api/v1/";

const axiosParams = {
  baseURL,
  timeout: 10000,
  headers: {
    "content-type": "application/json"
  }
};

if (localStorage.getItem("token"))
  axiosParams.headers.Authorization = localStorage.getItem("token");

export let api = axios.create(axiosParams);

export default api;

configure({
  axios: api
});

api.interceptors.request.use(request => {
  const { Authorization, ...other } = request.headers;
  request.headers = other;

  const accessToken = localStorage.getItem("token");
  if (accessToken) request.headers.Authorization = accessToken;

  return request;
});

api.interceptors.response.use(
  response => {
    if (response.data.access_token)
      writeAccessToken(response.data.access_token);
    return response;
  },
  error => {
    if (isTokenExpiredError(error)) return resetToken(error);
    return Promise.reject(error);
  }
);

function isTokenExpiredError(error) {
  if (error?.response?.status === 401) return true;
  return false;
}

function writeAccessToken(access) {
  Object.assign(api.defaults, { headers: { Authorization: access } });
}

let requiests = [];
let fetchingToken = false;

async function resetToken(error) {
  try {
    if (!localStorage.getItem("refreshToken")) throw error;

    const retryOriginalRequest = new Promise(resolve => {
      requiests.push(access_token => {
        error.response.config.headers.Authorization = access_token;
        resolve(axios(error.response.config));
      });
    });

    if (!fetchingToken) {
      fetchingToken = true;

      const response = await axios({
        baseURL,
        method: "post",
        url: `/auth/refresh/`,
        data: {
          access_token: localStorage.getItem("token"),
          refresh_token: localStorage.getItem("refreshToken")
        }
      });
      if (!response.data) throw error;

      const { access_token, refresh_token } = response.data;
      localStorage.setItem("token", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      writeAccessToken(access_token);
      fetchingToken = false;

      requiests.forEach(callback => callback(access_token));
      requiests = [];
    }

    return retryOriginalRequest;
  } catch (err) {
    store.dispatch(actions.logoutSuccess());
    return Promise.reject(error);
  }
}

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Paper, Toolbar } from "containers";

import UserView from "app/users/view/form";

import { selectors } from "store/auth";
import { Typography } from "components/Typography";
import { IconButton, Fab } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { ReactComponent as EditIcon } from "static/icons/edit.svg";
import { Loader, LoaderWrapper } from "components";
import Content from "containers/Content";

export const ProfileView = ({ me }) => (
  <Fragment>
    <Toolbar>
      <IconButton component={NavLink} to="/">
        <BackIcon />
      </IconButton>
      <Typography variant="h5" style={{ display: "flex", flex: 1 }}>
        {me?.first_name} {me?.last_name} profile
      </Typography>
    </Toolbar>
    <Paper>
      <Content>
        {me ? (
          <UserView user={me} />
        ) : (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        <Fab component={NavLink} to="/profile/edit">
          <EditIcon />
        </Fab>
      </Content>
    </Paper>
  </Fragment>
);

export default connect(state => ({
  me: selectors.me(state)
}))(ProfileView);

import React, { useEffect } from "react";
import { connect } from "react-redux";

import Loader, { LoaderWrapper } from "components/Loader/Loader";

import { selectors, actions } from "store/auth";

const AuthProvider = ({ auth, fetchMe, children, ...other }) => {
  useEffect(() => {
    fetchMe();
    return () => {};
  }, [fetchMe]);

  if (auth.isAuth == null)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  return children;
};

export default connect(
  state => ({ auth: selectors.auth(state) }),
  dispatch => ({ fetchMe: () => dispatch(actions.fetchMe()) })
)(AuthProvider);

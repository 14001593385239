import React, { Fragment } from "react";

import { NavLink } from "react-router-dom";

import { Paper, Toolbar, Separator } from "containers";
import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import Content from "containers/Content";
export const ProfileChangePassword = ({ location }) => {
  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/profile">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">Changing password</Typography>
      </Toolbar>

      <Paper>
        <Content>
          <Typography color="textSecondary">
            Password change success.
          </Typography>

          <Toolbar margin={false}>
            <Separator />
            <Button primary component={NavLink} to={"/profile"}>
              Back
            </Button>
          </Toolbar>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default ProfileChangePassword;

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import styles from "./Header.module.css";

import { Popover } from "components";
import { Separator } from "containers";
import { Button, MenuItem } from "components/Button";

import { selectors, actions } from "store/auth";

import { ReactComponent as ArrowDownIcon } from "static/icons/arrowDown.svg";

const Header = ({ me, logout }) => (
  <div className={styles.header}>
    <div className={styles.container}>
      <NavLink to="/">
        <img alt="Company logo" width="120" height="36" src="/img/logo2.png" />
      </NavLink>
      <Separator />
      <Popover
        trigger={() => (
          <Button className={styles.header_button} color="default">
            {me?.first_name} {me?.last_name}
            <ArrowDownIcon width="24" height="24" />
          </Button>
        )}
        position="bottom center"
        closeOnDocumentClick
      >
        <Fragment>
          <MenuItem component={NavLink} to="/profile">
            View profile
          </MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Fragment>
      </Popover>
    </div>
  </div>
);

export default connect(
  state => ({
    me: selectors.me(state)
  }),
  dispatch => ({
    logout: () => dispatch(actions.logout())
  })
)(Header);

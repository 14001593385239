import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Page from "app/Page";

import NewUser from "./new";
import ViewUser from "./view/view";
import EditUser from "./edit/edit";
import UsersList from "./list/list";
import Permission from "containers/Permission";

const Auth = () => (
  <Page>
    <Permission required={["admin", "manager"]} fallback={<Redirect to="/" />}>
      <Switch>
        <Route path="/users/new" component={NewUser} />
        <Route path="/users/:id/edit" component={EditUser} />
        <Route path="/users/:id" component={ViewUser} />
        <Route path="/users" component={UsersList} />
      </Switch>
    </Permission>
  </Page>
);

export default Auth;

import { createSelector } from "@reduxjs/toolkit";

const router = state => state.router;
const location = createSelector(router, router => router.location);
const state = createSelector(location, location => location.state);

export const selectors = {
  location,
  state
};

import React, { forwardRef } from "react";
import cx from "classnames";

import styles from "./Button.module.css";

export const Button = forwardRef(
  (
    {
      disabled,
      component: Component = "button",
      color = "accent",
      className,
      primary,
      fullWidth,
      ...other
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={cx(
        styles.button,
        {
          [styles.button_disabled]: disabled,
          [styles.button_primary]: primary,
          [styles.button_full_width]: fullWidth
        },
        styles["button_color_" + (primary ? "contrast" : color)],
        className
      )}
      disabled={disabled}
      {...other}
    />
  )
);

export default Button;

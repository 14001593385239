import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";
import useForm from "react-hook-form";

import { Button } from "components/Button";
import { FormTitle } from "components/Typography";
import { NavLink } from "react-router-dom";
import { Form } from "components/Form";
import { Loader } from "components";

import { errorToForm } from "core/apiUtils";
import api from "core/axios";

import { ReactComponent as BackIcon } from "static/icons/back.svg";

import CodeConfirmationForm from "app/profile/password_change/code_confirmation_form";

const CodeConfirmation = ({ location, push, replace }) => {
  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  useEffect(() => {
    if (location?.state?.email == null) replace("/login/recovery");
  });

  const onSubmit = values => {
    const params = { email: location?.state?.email, ...values };

    return api
      .post("/auth/password_recovery/check_code/", params)
      .then(() =>
        push({ ...location, pathname: "/login/change", state: params })
      )
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Button
        component={NavLink}
        to={location => ({ ...location, pathname: "/login/recovery" })}
      >
        <BackIcon width="24" height="24" />
        Back
        <div style={{ flex: 1 }}></div>
      </Button>
      <div style={{ height: 30 }} />
      <FormTitle align="center">Password recovery</FormTitle>
      <div style={{ height: 60 }} />
      <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
        <CodeConfirmationForm register={register} errors={errors} />
        <Button fullWidth primary disabled={isSubmitting} type="submit">
          {isSubmitting ? <Loader /> : "Send"}
        </Button>
      </Form>
    </Fragment>
  );
};

export default connect(null, dispatch => ({
  push: location => dispatch(push(location)),
  replace: location => dispatch(replace(location))
}))(CodeConfirmation);

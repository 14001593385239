import { createSlice, createSelector } from "@reduxjs/toolkit";

import api from "core/axios";
import { replace } from "connected-react-router";

import { selectors as routerSelectors } from "./router";

const auth = createSlice({
  name: "auth",
  initialState: { isAuth: null, me: null },
  reducers: {
    fetchMeSuccess: (state, action) => {
      state.isAuth = true;
      state.me = action.payload;
    },
    fetchMeFail: state => {
      state.isAuth = false;
    },
    loginSuccess: (state, { payload: { access_token, refresh_token } }) => {
      localStorage.setItem("token", access_token);
      localStorage.setItem("refreshToken", refresh_token);

      state.isAuth = true;
    },
    logoutSuccess: state => {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");

      state.isAuth = false;
    }
  }
});

const authSelector = state => state.auth;
const meSelector = createSelector(authSelector, auth => auth.me);

export const selectors = {
  auth: authSelector,
  me: meSelector
};

export const actions = {
  ...auth.actions,
  fetchMe: () => async (dispatch, getState) => {
    const location = routerSelectors.location(getState());

    try {
      const { data } = await api.get("/me/");
      dispatch(auth.actions.fetchMeSuccess(data));

      if (location.pathname.startsWith("/login")) dispatch(replace("/"));
    } catch (error) {
      dispatch(auth.actions.fetchMeFail());

      const query = {
        ...location.query
      };

      if (!location.pathname.startsWith("/login")) {
        query.returnUrl = location.pathname;
        dispatch(
          replace({
            ...location,
            pathname: "/login",
            query,
            search: new URLSearchParams(query).toString()
          })
        );
      }
    }
  },
  login: ({ user, access_token, refresh_token }) => (dispatch, getState) => {
    dispatch(auth.actions.fetchMeSuccess(user));
    dispatch(auth.actions.loginSuccess({ access_token, refresh_token }));

    const location = routerSelectors.location(getState());
    const { returnUrl, ...query } = location.query;

    dispatch(
      replace({
        pathname: decodeURIComponent(returnUrl) ?? "/",
        query,
        search: new URLSearchParams(query).toString()
      })
    );
  },
  logout: () => dispatch => {
    dispatch(auth.actions.logoutSuccess());
    dispatch(actions.fetchMe());
  }
};

export const reducer = auth.reducer;

export default auth;

import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { useDebouncedCallback } from "use-debounce";
import HookSelect from "components/TextField/Select/HookSelect";
import { isEqual } from "lodash";

export function CustomerSelect({ name, watch, setValue, ...other }) {
  const value = watch(name);
  const [search, setSearch] = useState(null);

  const [{ data }, refresh] = useAxios(`/customers/`, {
    manual: true,
    useCache: false
  });

  useEffect(() => {
    const params = {
      limit: 25
    };
    if (value) params.id = value;
    refresh({
      params
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = data?.results ?? [];

  useEffect(() => {
    if (typeof value != "object") return;
    const currentValue = options?.find(x => x.id === value?.id);
    if (currentValue && !isEqual(value, currentValue))
      setValue(name, currentValue);
  }, [name, value, options, setValue]);

  const [getCustomers] = useDebouncedCallback(() => {
    if (search != null)
      refresh({
        params: {
          company_name: search,
          limit: 25
        }
      });
  }, 1000);

  React.useEffect(getCustomers, [search]);

  return (
    <HookSelect
      menuPlacement="auto"
      name={name}
      watch={watch}
      setValue={setValue}
      isClearable={true}
      options={options}
      getOptionLabel={x => x.company_name}
      getOptionValue={x => x.id}
      onInputChange={search => setSearch(search)}
      {...other}
    />
  );
}

export default CustomerSelect;

import React, { useCallback } from "react";

import { Separator } from "containers";
import { IconButton } from "components/Button";

import "./TablePaginationSelect.css";
import styles from "./TablePagination.module.css";

import { Typography } from "components/Typography";

import { ReactComponent as BackIcon } from "static/icons/forward-left.svg";
import { ReactComponent as NextIcon } from "static/icons/forward-right.svg";
import Select from "components/TextField/Select/Select";

export const paginationValues = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" }
];

export const TablePagination = ({
  limit,
  count,
  offset,
  onPaginationChange,
  onLimitChange
}) => {
  let totalPage = Math.ceil(count / limit);
  let currentPage = offset >= count ? -1 : offset / limit + 1;
  if (currentPage < 0) {
    currentPage = 0;
    totalPage = 0;
  }

  const onPrev = useCallback(() => onPaginationChange(offset - limit), [
    onPaginationChange,
    offset,
    limit
  ]);

  const onNext = useCallback(() => onPaginationChange(offset + limit), [
    onPaginationChange,
    offset,
    limit
  ]);

  const onLimit = useCallback(option => onLimitChange(option.value), [
    onLimitChange
  ]);

  return (
    <div className={styles.pagination}>
      <IconButton onClick={onPrev}>
        <BackIcon />
      </IconButton>
      <Typography variant="secondary">
        {currentPage} / {totalPage}
      </Typography>
      <IconButton onClick={onNext}>
        <NextIcon />
      </IconButton>
      <Separator />
      <Select
        menuPlacement="auto"
        inputProps={{ className: styles.select }}
        classNamePrefix="combobox2"
        value={paginationValues.find(x => x.value === limit)}
        options={paginationValues}
        onChange={onLimit}
        style={{ margin: 3 }}
      ></Select>
    </div>
  );
};

export default TablePagination;

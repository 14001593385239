import React from "react";
import cx from "classnames";

import styles from "./TextField.module.css";

export const Input = ({
  startAdornment,
  endAdornment,
  component: Component = "input",
  inputRef,
  inputProps,
  error,
  style,
  ...other
}) => (
  <div
    className={cx(styles.field, {
      [styles.error]: error
    })}
    style={style}
  >
    {startAdornment}
    <Component
      ref={inputRef}
      className={styles.input}
      {...other}
      {...inputProps}
    />
    {endAdornment}
  </div>
);

export default Input;

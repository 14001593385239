import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Page from "app/Page";

import NewCustomer from "./new";
import ViewCustomer from "./view/view";
import EditCustomer from "./edit/edit";
import CustomersList from "./list/list";
import Permission from "containers/Permission";

const Customers = () => (
  <Page>
    <Permission required={["admin"]} fallback={<Redirect to="/" />}>
      <Switch>
        <Route path="/customers/new" component={NewCustomer} />
        <Route path="/customers/:id/edit" component={EditCustomer} />
        <Route path="/customers/:id" component={ViewCustomer} />
        <Route path="/customers" component={CustomersList} />
      </Switch>
    </Permission>
  </Page>
);

export default Customers;

import React, { useState, useEffect } from "react";
import Select from "./Select";

const HookSelect = ({
  name,
  options,
  register,
  watch,
  setValue,
  onChange = null,
  ...other
}) => {
  const formValue = watch(name);
  const [values, setReactSelect] = useState();

  useEffect(() => setReactSelect(formValue), [formValue, options]);

  const handleMultiChange = async selectedOption => {
    setReactSelect(selectedOption);
    await setValue(name, selectedOption);
    // eslint-disable-next-line no-unused-expressions
    onChange?.(selectedOption);
  };

  useEffect(() => {
    register({ name });
  }, [name, register]);

  return (
    <Select
      name={name}
      value={values}
      options={options}
      onChange={handleMultiChange}
      {...other}
    />
  );
};

export default HookSelect;

import React from "react";
import cx from "classnames";
import Label from "./Label";

import styles from "./TextField.module.css";

import Input from "./Input";
import { Typography } from "components/Typography";

export const TextField = ({
  className,
  style,
  inputProps,
  error,
  id,
  label,
  ...other
}) => (
  <div className={cx(styles.container, className)} style={style}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <div className={styles.inputAndError}>
      <Input id={id} error={error} {...inputProps} {...other} />
      {error && (
        <Typography
          className={styles.field_error}
          component="p"
          color="error"
          align="center"
        >
          {error}
        </Typography>
      )}
    </div>
  </div>
);

export default TextField;

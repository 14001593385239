import React, { Fragment, useCallback } from "react";
import cx from "classnames";
import { TextField } from "components/TextField";
import HookSelect from "components/TextField/Select/HookSelect";

import world from "static/dictionaries/world.json";
import { Group, Column } from "components/Group/Group";
import UsersSelect from "app/users/select";
import { components } from "react-select";

import styles from "./CustomerEdit.module.css";
import { ReactComponent as AddIcon } from "static/icons/add.svg";

export { world };

export const newUserId = "new";
const userKeys = ["id", "first_name", "last_name", "username", "email"];

const UserValueContainer = props => {
  const { getValue } = props;
  const value = getValue();

  // eslint-disable-next-line eqeqeq
  if (value.some(x => x.id == newUserId))
    return (
      <div className={cx(styles.value)}>
        <AddIcon />
        New user
      </div>
    );
  return <components.ValueContainer {...props} />;
};

const UserOption = ({ innerRef, innerProps, data, ...other }) => {
  // eslint-disable-next-line eqeqeq
  return data.id == newUserId ? (
    <div ref={innerRef} {...innerProps} className={cx(styles.option)}>
      <AddIcon />
      Create a new user
    </div>
  ) : (
    <components.Option
      innerRef={innerRef}
      innerProps={innerProps}
      data={data}
      {...other}
    />
  );
};

function primaryUserError(error) {
  if (error) {
    if (error.type === "invalid")
      return "You can not assign this user to the selected company";
    return error.message;
  }

  return null;
}

export const CustomerEditForm = ({
  customer,
  register,
  watch,
  setValue,
  errors
}) => {
  const primaryUser = watch("primary_user.selected");

  const usersTransform = useCallback(
    options => [{ id: newUserId }, ...options],
    []
  );

  const onUserChange = useCallback(
    user =>
      userKeys.forEach(key => setValue(`primary_user.${key}`, user?.[key])),
    [setValue]
  );

  return (
    <Fragment>
      <Group>
        <Column>
          <TextField
            name="company_name"
            label="Company name"
            inputRef={register({
              required: "Customer name required"
            })}
            error={errors?.company_name?.message}
          />
          <TextField
            name="phone_number"
            label="Phone number"
            inputRef={register({
              required: "Phone number required"
            })}
            error={errors?.phone_number?.message}
          />
          <TextField
            name="address_line_one"
            label="Address"
            inputRef={register({
              required: "Address required"
            })}
            error={errors?.address_line_one?.message}
          />
          <TextField
            name="address_line_two"
            label="Address 2"
            inputRef={register}
            error={errors?.address_line_two?.message}
          />
          <HookSelect
            menuPlacement="auto"
            name="country"
            label="Country"
            register={register({
              required: "Country required"
            })}
            watch={watch}
            setValue={setValue}
            options={world}
            error={errors?.country?.message}
            getOptionLabel={x => x.name}
            getOptionValue={x => x.alpha3}
          />

          <TextField
            name="city"
            label="City"
            inputRef={register({
              required: "City required"
            })}
            error={errors?.city?.message}
          />
          <TextField
            name="state"
            label="State"
            inputRef={register({
              required: "State required"
            })}
            error={errors?.state?.message}
          />
          <TextField
            name="zip"
            label="Zip"
            inputRef={register({
              required: "Zip required"
            })}
            error={errors?.zip?.message}
          />
        </Column>
        <Column>
          <UsersSelect
            name="primary_user.selected"
            label="Primary user"
            register={register}
            watch={watch}
            setValue={setValue}
            error={primaryUserError(errors?.primary_user)}
            optionsTransform={usersTransform}
            components={{
              Option: UserOption,
              ValueContainer: UserValueContainer
            }}
            onChange={onUserChange}
          />
          {primaryUser && (
            <Fragment>
              <TextField
                name="primary_user.first_name"
                label="First name"
                inputRef={register({
                  required: "First name required"
                })}
                error={
                  errors?.first_name?.message ??
                  errors?.["primary_user.first_name"]?.message
                }
                style={{ display: primaryUser ? null : "none" }}
              />
              <TextField
                name="primary_user.last_name"
                label="Last name"
                inputRef={register({
                  required: "Last name required"
                })}
                error={
                  errors?.last_name?.message ??
                  errors?.["primary_user.last_name"]?.message
                }
                style={{ display: primaryUser ? null : "none" }}
              />
              <TextField
                name="primary_user.username"
                label="User name"
                inputRef={register({
                  required: "Username required"
                })}
                error={
                  errors?.username?.message ??
                  errors?.["primary_user.username"]?.message
                }
                style={{ display: primaryUser ? null : "none" }}
              />
              <TextField
                name="primary_user.email"
                label="Email"
                type="email"
                inputRef={register({
                  required: "Email required"
                })}
                error={
                  errors?.email?.message ??
                  errors?.["primary_user.email"]?.message
                }
                style={{ display: primaryUser ? null : "none" }}
              />
            </Fragment>
          )}
        </Column>
      </Group>
    </Fragment>
  );
};

export default CustomerEditForm;

import React, { useEffect } from "react";
import useAxios from "axios-hooks";
import { Fragment } from "react";

import { ListItem } from "components/List";
import Permission from "containers/Permission";
import { roles } from "../edit/form";

export const UserView = ({ user }) => {
  const [{ data, loading }, loadCustomer] = useAxios(
    `/customers/${user.customer_account}/`,
    {
      manual: true,
      useCache: false
    }
  );

  useEffect(() => {
    if (user.customer_account) loadCustomer();
  }, [user.customer_account, loadCustomer]);

  return (
    <Fragment>
      <ListItem label={"First name"} text={user.first_name} />
      <ListItem label={"Last name"} text={user.last_name} />
      <ListItem label={"User name"} text={user.username} />
      <ListItem label={"Email"} text={user.email} />
      <ListItem
        label={"Role"}
        text={roles
          .filter(x => user.groups.includes(x.value))
          .map(x => x.label)
          .join(", ")}
      />
      <Permission required={["admin", "manager"]}>
        <ListItem
          label={"Customer"}
          text={data || loading ? data?.company_name : "No company"}
        />
      </Permission>
    </Fragment>
  );
};

export default UserView;

import React from "react";
import cx from "classnames";

import styles from "./Group.module.css";

export const Group = ({ className, ...other }) => (
  <div className={cx(styles.group, className)} {...other} />
);

export const Column = ({ className, ...other }) => (
  <div className={cx(styles.column, className)} {...other} />
);

export const Row = ({ className, ...other }) => (
  <div className={cx(styles.row, className)} {...other} />
);

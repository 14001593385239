import React, { Fragment } from "react";
import useAxios from "axios-hooks";
import UserView from "./form";
import { Loader } from "components";
import { Toolbar, Paper } from "containers";
import { IconButton, Fab } from "components/Button";
import { Typography } from "components/Typography";
import { NavLink } from "react-router-dom";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { ReactComponent as EditIcon } from "static/icons/edit.svg";
import Content from "containers/Content";

export const ViewUser = ({ match }) => {
  const [{ data, loading }] = useAxios(`/users/${match.params.id}/`, {
    useCache: false
  });

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/users">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">
          {data?.first_name} {data?.last_name}
        </Typography>
      </Toolbar>
      <Paper>
        <Content>
          {loading || data == null ? (
            <Loader />
          ) : (
            <UserView user={data}></UserView>
          )}
        </Content>
        <Fab component={NavLink} to={`/users/${match.params.id}/edit`}>
          <EditIcon />
        </Fab>
      </Paper>
    </Fragment>
  );
};

export default ViewUser;

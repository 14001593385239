import * as React from "react";
import cx from "classnames";
import ReactSelect from "react-select";

import "./Select.css";
import styles from "../TextField.module.css";

import { Typography } from "components/Typography";
import Label from "../Label";

const Select = ({
  className,
  style,
  error,
  label,
  id,
  inputProps,
  ...other
}) => (
  <div className={cx(styles.container, className)} style={style}>
    {label && <Label htmlFor={id}>{label}</Label>}
    <div className={styles.inputAndError}>
      <ReactSelect
        menuPortalTarget={document.querySelector("body")}
        className={styles.field}
        classNamePrefix="combobox"
        {...inputProps}
        {...other}
      />
      {error && (
        <Typography
          className={styles.field_error}
          component="p"
          color="error"
          align="center"
        >
          {error}
        </Typography>
      )}
    </div>
  </div>
);

export default Select;

import { useEffect } from "react";

function HookHiddenField({ register, ...other }) {
  useEffect(() => {
    register(other);
  }, [register, other]);
  return null;
}

export default HookHiddenField;

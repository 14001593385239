import React, { Fragment } from "react";
import useAxios from "axios-hooks";
import { NavLink } from "react-router-dom";

import { useTableData } from "core/apiUtils";

import NavBar from "app/navigation/NavBar";
import SearchField from "components/TextField/SearchField";
import {
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell,
  TableLoader,
  TablePagination,
  TableRow,
  TableCell
} from "components/Table";
import { Fab } from "components/Button";
import { Separator, Toolbar } from "containers";

import { ReactComponent as AddIcon } from "static/icons/add.svg";
import { roles } from "../edit/form";

const UsersList = () => {
  const [{ data, loading, response }, refresh] = useAxios(
    {
      url: `/users/`,
      params: { limit: 25 }
    },
    { useCache: false }
  );

  const [
    limit,
    count,
    offset,
    items,
    handleLimit,
    handlePagination,
    handleSearch
  ] = useTableData(data, response, refresh);

  return (
    <Fragment>
      <Toolbar>
        <NavBar />
        <Separator />
        <SearchField onSearchChange={handleSearch("full_name")} />
      </Toolbar>
      <Table
        fab={
          <Fab component={NavLink} to="/users/new">
            <AddIcon />
          </Fab>
        }
      >
        <TableHeader>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Email</TableHeaderCell>
          <TableHeaderCell>Role</TableHeaderCell>
          <TableHeaderCell>ID</TableHeaderCell>
        </TableHeader>
        <TableBody>
          {loading ? (
            <TableLoader />
          ) : (
            items.map((user, index) => (
              <TableRow key={index} to={`/users/${user.id}`}>
                <TableCell>
                  {user.first_name} {user.last_name}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {roles
                    .filter(x => user.groups.includes(x.value))
                    .map(x => x.label)
                    .join(", ")}
                </TableCell>
                <TableCell>{user.id}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      <TablePagination
        limit={limit}
        count={count}
        offset={offset}
        onLimitChange={handleLimit}
        onPaginationChange={handlePagination}
      />
    </Fragment>
  );
};

export default UsersList;

import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { replace, push } from "connected-react-router";
import useForm from "react-hook-form";
import { NavLink } from "react-router-dom";

import { Form } from "components/Form";
import { FormTitle } from "components/Typography";
import { Button } from "components/Button";
import { Loader } from "components";

import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import { ReactComponent as BackIcon } from "static/icons/back.svg";

import ChangePasswordForm from "app/profile/password_change/change_password_form";

const ChangePassword = ({ location, push }) => {
  const {
    watch,
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  useEffect(() => {
    if (location?.state?.email == null) replace("/login/recovery");
  });

  const onSubmit = values => {
    const params = {
      email: location?.state?.email,
      code: location?.state?.code,
      ...values
    };

    return api
      .post("/auth/password_recovery/reset_password/", params)
      .then(() => {
        push({ ...location, pathname: "/login/recovery_success" });
      })
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Button
        component={NavLink}
        to={location => ({ ...location, pathname: "/login/code" })}
      >
        <BackIcon width="24" height="24" />
        Back
        <div style={{ flex: 1 }}></div>
      </Button>
      <div style={{ height: 30 }} />
      <FormTitle align="center">Password recovery</FormTitle>
      <div style={{ height: 60 }} />
      <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
        <ChangePasswordForm register={register} errors={errors} watch={watch} />
        <Button fullWidth primary disabled={isSubmitting} type="submit">
          {isSubmitting ? <Loader /> : "Send"}
        </Button>
      </Form>
    </Fragment>
  );
};

export default connect(null, dispatch => ({
  replace: location => dispatch(replace(location)),
  push: location => dispatch(push(location))
}))(ChangePassword);

import React, { useCallback, useState } from "react";

import { Button } from "components/Button";
import { Typography } from "components/Typography";
import Dialog from "components/Popover/Dialog";
import { Toolbar, Separator, Paper } from "containers";
import api from "core/axios";
import { LoaderWrapper, Loader } from "components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { errorToForm } from "core/apiUtils";
import Content from "containers/Content";

export const DeleteUser = ({ userId, push }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleDelete = useCallback(() => {
    setLoading(true);

    api
      .delete(`/users/${userId}/`)
      .then(() => push("/users"))
      .catch(error => {
        setLoading(false);
        setErrors(errorToForm(error));
      });
  }, [userId, push]);

  return (
    <Dialog
      modal
      trigger={
        <Button component="a" color="error">
          Delete user
        </Button>
      }
    >
      {close => (
        <Paper>
          <Content>
            {loading && (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            )}
            <Typography margin component="p" variant="h6">
              Delete
            </Typography>
            <Typography margin component="p">
              Are you sure you want to delete this user?
            </Typography>
            {errors && errors[0] && (
              <Typography margin component="p" color="error">
                {errors[0][2]}
              </Typography>
            )}
            <Toolbar margin={false} style={{ marginBottom: -25 }}>
              <Separator />
              <Button component="a" onClick={close}>
                Cancel
              </Button>
              <Button component="a" color="error" onClick={handleDelete}>
                Delete
              </Button>
            </Toolbar>
          </Content>
        </Paper>
      )}
    </Dialog>
  );
};

export default connect(null, dispatch => ({
  push: location => dispatch(push(location))
}))(DeleteUser);

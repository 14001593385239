import React from "react";
import cx from "classnames";

import styles from "./Typography.module.css";

export const Typography = ({
  className,
  noWrap = false,
  margin = false,
  component: Component = "span",
  color = "default",
  variant = "default",
  ...other
}) => (
  <Component
    className={cx(
      styles.typography,
      styles["color_" + color],
      styles["variant_" + variant],
      {
        [styles.noWrap]: noWrap,
        [styles.margin]: margin
      },
      className
    )}
    {...other}
  ></Component>
);

import moment from "moment";

import { store } from "../index";
import { actions } from "store/auth";

const eventKey = "activity";
const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];

const idle = moment.duration(10, "minutes").asSeconds();
let timerStart = moment();

function resetTimer() {
  timerStart = moment();
  window.localStorage.setItem(eventKey, timerStart.toISOString());
}

function checkTimeSpan() {
  const duration = moment.duration(moment().diff(timerStart)).asSeconds();
  if (duration >= idle) {
    store.dispatch(actions.logout());
    resetTimer();
  }
}

function checkAction() {
  checkTimeSpan();
  resetTimer();
}

function receiveMessage(event) {
  if (event.key === eventKey) timerStart = moment(event.newValue);
}

window.addEventListener("load", checkAction, true);

events.forEach(function(name) {
  document.addEventListener(name, checkAction, true);
});

window.addEventListener("storage", receiveMessage);

setInterval(checkTimeSpan, 1000);

import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Paper, Toolbar, Separator } from "containers";

import { selectors } from "store/auth";
import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import useForm from "react-hook-form";
import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import { Form } from "components/Form";
import { Loader, LoaderWrapper } from "components";
import CodeConfirmationForm from "./code_confirmation_form";
import { push } from "connected-react-router";
import Content from "containers/Content";

export const ProfileEdit = ({ location, me, push }) => {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  useEffect(() => {
    setLoading(true);
    api
      .post("/auth/password_recovery/send_code/", {
        email: me.email
      })
      .then(() => setLoading(false))
      .catch(error => {
        errorToForm(error).forEach(x => setError(...x));
        setLoading(false);
      });

    return () => {};
  }, [me.email, setError]);

  const onSubmit = values => {
    const params = { email: me?.email, ...values };

    return api
      .post("/auth/password_recovery/check_code/", params)
      .then(() =>
        push({
          ...location,
          pathname: "/profile/change_password",
          state: params
        })
      )
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/profile/edit">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">Changing password</Typography>
      </Toolbar>
      {loading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <Paper>
        <Content>
          <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
            <CodeConfirmationForm errors={errors} register={register} />
            <Toolbar margin={false}>
              <Separator />
              <Button disabled={isSubmitting} primary>
                {isSubmitting ? <Loader /> : "Next"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default connect(
  state => ({
    me: selectors.me(state)
  }),
  dispatch => ({
    push: to => dispatch(push(to))
  })
)(ProfileEdit);

import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import useAxios from "axios-hooks";
import useForm from "react-hook-form";

import { Loader, LoaderWrapper } from "components";
import { Paper, Toolbar, Separator } from "containers";

import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { Form } from "components/Form";
import api from "core/axios";
import { errorToForm } from "core/apiUtils";
import UserEditForm, { roles } from "./form";
import { NavLink } from "react-router-dom";
import DeleteUser from "./delete";
import { selectors } from "store/auth";
import Content from "containers/Content";

export function editUser(id, { customer_account, groups, ...other }) {
  return api.patch(`/users/${id}/`, {
    ...other,
    customer_account: customer_account ? customer_account.id : null,
    groups: groups && [groups.value]
  });
}

export const EditUser = ({ me, match, push }) => {
  const [{ data, loading }] = useAxios(`/users/${match.params.id}/`, {
    useCache: false
  });

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: data
  });

  useEffect(() => {
    if (data == null) return;
    const { customer_account, groups, ...other } = data;
    Object.entries({
      customer_account: customer_account ? { id: customer_account } : null,
      groups: roles.find(x => groups.includes(x.value)),
      ...other
    }).forEach(([key, value]) => setValue(key, value));
  }, [data, setValue]);

  const currentGroup = watch("groups");
  useEffect(() => {
    if (typeof currentGroup == "object" && currentGroup.value === "admin")
      setValue("customer_account", null);
  }, [currentGroup, setValue]);

  const onSubmit = values =>
    editUser(match.params.id, values)
      .then(() => push(`/users/${match.params.id}/`))
      .catch(error => errorToForm(error).forEach(x => setError(...x)));

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to={`/users/${match.params.id}`}>
          <BackIcon />
        </IconButton>
        <Typography variant="h5">
          {data?.first_name} {data?.last_name} editing
        </Typography>
      </Toolbar>
      <Paper>
        <Content>
          <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
            {loading ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <UserEditForm
                me={me}
                errors={errors}
                register={register}
                setValue={setValue}
                watch={watch}
              />
            )}
            <Toolbar margin={false}>
              <Separator />
              <DeleteUser userId={match.params.id} />
              <Button disabled={isSubmitting} primary type="submit">
                {isSubmitting ? <Loader /> : "Save"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default connect(
  state => ({
    me: selectors.me(state)
  }),
  dispatch => ({
    push: location => dispatch(push(location))
  })
)(EditUser);

import React, { Fragment } from "react";

import { Paper, Toolbar, Separator } from "containers";

import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { Form } from "components/Form";
import { errorToForm } from "core/apiUtils";
import api from "core/axios";
import useForm from "react-hook-form";
import { Loader } from "components";

import CutomerEditForm from "./edit/form";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Content from "containers/Content";
import { setPrimaryUser } from "./edit/edit";

export const NewCustomer = ({ push }) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = async ({
    primary_user,
    country,
    address_line_two,
    ...other
  }) => {
    try {
      const newCustomer = {
        country: country.alpha3,
        ...other
      };
      if (address_line_two) newCustomer.address_line_two = address_line_two;

      const customer = await api.post("/customers/", newCustomer);

      await setPrimaryUser(customer.data, primary_user);

      push(`/customers`);
    } catch (error) {
      errorToForm(error).forEach(x => setError(...x));
    }
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/customers">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">New customer</Typography>
      </Toolbar>
      <Paper>
        <Content>
          <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
            <CutomerEditForm
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
            <Toolbar margin={false}>
              <Separator />
              <Button disabled={isSubmitting} primary>
                {isSubmitting ? <Loader /> : "Create"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default connect(null, dispatch => ({
  push: location => dispatch(push(location))
}))(NewCustomer);

export function processError(code, message) {
  switch (code) {
    case "invalid_group_customer_combination":
      return [code, "You can not assign this user to the selected company"];
    default:
      return [code, message];
  }
}

export function errorToForm(error) {
  if (error == null) return [["form", "undefined", "Request timeout"]];

  const serverError = error?.response?.data?.error;

  if (serverError) {
    return [["form", ...processError(serverError.code, serverError.message)]];
  }

  const serverFileldsError = error?.response?.data?.errors;

  if (serverFileldsError)
    return serverFileldsError.map(error => [
      error.field,
      ...processError(error.code, error.message)
    ]);

  if (error.message === "Network Error")
    return [["form", "network", "No internet connection"]];

  try {
    switch (error.response.status) {
      case 403:
        return [
          ["form", "auth", "Authentication credentials were not provided"]
        ];
      case 500:
      case 502:
      case 503:
        return [["form", "server", "Server error"]];
      default:
        break;
    }
  } catch (errr) {
    console.log(error);
  }

  return [["error", "error", "Error"]];
}

const defaultValues = [null, undefined, ""];

const isDefaultValue = value =>
  defaultValues.indexOf(value) > -1 || isNaN(value) ? true : false;

function getParams(url) {
  let result = {};
  if (url)
    result = Object.fromEntries(
      Array.from(new URL(url).searchParams).filter(
        ([, value]) => !isDefaultValue(value)
      )
    );
  return result;
}

export function getCurrentUrl(config) {
  if (config)
    return `${config.url}?${new URLSearchParams(
      Object.entries(config.params)
    ).toString()}`;
  return window.location.origin;
}

export function useTableData(data = {}, response, refresh) {
  const { count, next, previous, results } = data;

  const currentParams = getParams(getCurrentUrl(response?.config));
  const nextParams = getParams(next);
  const prevParams = getParams(previous);

  const limit = parseInt(
    nextParams.limit ?? prevParams.limit ?? currentParams.limit
  );

  const offset =
    parseInt(nextParams.offset) - limit ||
    parseInt(prevParams.offset) + limit ||
    parseInt(currentParams.offset) ||
    0;

  const params = {
    ...nextParams,
    ...prevParams,
    limit,
    offset
  };

  const handleLimit = newLimit =>
    refresh({
      params: {
        ...params,
        offset: 0,
        limit: newLimit
      }
    });

  const handlePagination = newOffset => {
    if (newOffset < 0 || newOffset > count) return;
    refresh({
      params: {
        ...params,
        offset: newOffset
      }
    });
  };

  const handleSearch = field => value =>
    refresh({
      params: {
        ...params,
        offset: 0,
        [field]: value
      }
    });

  const handleRefresh = () =>
    refresh({
      params: {
        ...params
      }
    });

  return [
    limit ?? 0,
    count ?? 0,
    offset ?? 0,
    results ?? [],
    handleLimit,
    handlePagination,
    handleSearch,
    handleRefresh
  ];
}

import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { IconButton } from "components/Button";

import { ReactComponent as SearchIcon } from "static/icons/search.svg";
import TextField from "./TextField";

export const SearchField = ({ onSearchChange, ...other }) => {
  const [value, setValue] = useState("");

  const [changeSearch] = useDebouncedCallback(value => {
    onSearchChange(value);
  }, 1000);

  const handleChange = event => {
    const value = event.target.value;
    setValue(value);
    changeSearch(value);
  };

  return (
    <TextField
      placeholder="Search"
      endAdornment={
        <IconButton component="a" onClick={() => onSearchChange(value)}>
          <SearchIcon width="24" height="24" />
        </IconButton>
      }
      value={value}
      onChange={handleChange}
    />
  );
};

export default SearchField;

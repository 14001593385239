import React from "react";
import cx from "classnames";

import styles from "./Form.module.css";

import { Typography } from "components/Typography";

export const Form = ({ className, error, children, ...other }) => (
  <form className={cx(styles.form, className)} {...other}>
    {error && (
      <Typography
        className={styles.error}
        component="p"
        color="error"
        align="center"
      >
        {error}
      </Typography>
    )}
    {children}
  </form>
);

export default Form;

import React, { Fragment } from "react";

import Header from "./header/Header";
import Container from "containers/Container";

const Page = props => (
  <Fragment>
    <Header />
    <Container {...props} />
  </Fragment>
);

export default Page;

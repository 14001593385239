import React from "react";
import cx from "classnames";

import buttonStyles from "./Button.module.css";
import styles from "./Fab.module.css";

export const Fab = ({
  color = "contrast",
  component: Component = "button",
  className,
  ...other
}) => (
  <Component
    className={cx(styles.fab, buttonStyles["button_color_" + color], className)}
    {...other}
  />
);

export default Fab;

import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import useForm from "react-hook-form";
import { push } from "connected-react-router";

import { Form } from "components/Form";
import { TextField } from "components/TextField";
import { Button } from "components/Button";
import { FormTitle, Typography } from "components/Typography";
import Loader from "components/Loader/Loader";

import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import { ReactComponent as BackIcon } from "static/icons/back.svg";

const PasswordRecovery = ({ location, push }) => {
  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = values =>
    api
      .post("/auth/password_recovery/send_code/", values)
      .then(() => {
        push({ ...location, pathname: "/login/code", state: values });
      })
      .catch(error => errorToForm(error).forEach(x => setError(...x)));

  return (
    <Fragment>
      <Button
        component={NavLink}
        to={location => ({ ...location, pathname: "/login" })}
      >
        <BackIcon width="24" height="24" />
        Back
        <div style={{ flex: 1 }}></div>
      </Button>
      <div style={{ height: 30 }} />
      <FormTitle align="center">Password recovery</FormTitle>
      <div style={{ height: 60 }} />
      <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
        <Typography color="textSecondary">
          We will send a code to your Email account.
        </Typography>
        <TextField
          placeholder="Email"
          type="email"
          name="email"
          error={errors?.email?.message}
          inputRef={register({ required: "Email required" })}
        />
        <Button fullWidth primary disabled={isSubmitting} type="submit">
          {isSubmitting ? <Loader /> : "Send"}
        </Button>
      </Form>
    </Fragment>
  );
};

export default connect(null, dispatch => ({
  push: location => dispatch(push(location))
}))(PasswordRecovery);

import React, { useState } from "react";
import TextField from "./TextField";
import { IconButton } from "components/Button/IconButton";

import { ReactComponent as UnvisabilityIcon } from "static/icons/unvisability.svg";
import { ReactComponent as VisabilityIcon } from "static/icons/visability.svg";

export const PasswordField = props => {
  const [show, toggle] = useState(false);

  return (
    <TextField
      {...props}
      type={show ? "text" : "password"}
      endAdornment={
        <IconButton component="a" onClick={() => toggle(!show)}>
          {show ? (
            <UnvisabilityIcon width="24" height="24" />
          ) : (
            <VisabilityIcon width="24" height="24" />
          )}
        </IconButton>
      }
    />
  );
};

import React, { Fragment } from "react";

import { Paper, Toolbar, Separator } from "containers";

import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import { Form } from "components/Form";
import { errorToForm } from "core/apiUtils";
import api from "core/axios";
import useForm from "react-hook-form";
import { Loader } from "components";

import UserEditForm from "./edit/form";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { selectors } from "store/auth";
import Content from "containers/Content";

export function createUser({ customer_account, groups, ...other }) {
  return api.post("/users/", {
    customer_account: customer_account ? customer_account.id : null,
    groups: [groups.value],
    ...other
  });
}

export const NewUser = ({ me, push }) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: {
      customer_account: me.customer_account ? { id: me.customer_account } : null
    }
  });

  const onSubmit = values => {
    return createUser(values)
      .then(() => push(`/users/`))
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/users">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">New user</Typography>
      </Toolbar>
      <Paper>
        <Content>
          <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
            <UserEditForm
              me={me}
              register={register}
              setValue={setValue}
              watch={watch}
              errors={errors}
            />
            <Toolbar margin={false}>
              <Separator />
              <Button disabled={isSubmitting} primary>
                {isSubmitting ? <Loader /> : "Create"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default connect(
  state => ({
    me: selectors.me(state)
  }),
  dispatch => ({
    push: location => dispatch(push(location))
  })
)(NewUser);

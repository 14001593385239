import React from "react";
import cx from "classnames";

import styles from "./Containers.module.css";

const Container = ({ className, ...other }) => (
  <div className={cx(styles.container, className)} {...other} />
);

export default Container;

import React, { Fragment } from "react";

import { PasswordField } from "components/TextField";

export const ChangePasswordForm = ({ register, errors, watch }) => {
  return (
    <Fragment>
      <PasswordField
        name="new_password"
        placeholder="New password"
        inputRef={register({ required: "Password required" })}
        error={errors?.new_password?.message}
      />
      <PasswordField
        name="password_confirm"
        type="password"
        placeholder="Confirm the password"
        inputRef={register({
          required: "Confirm the password",
          validate: {
            same: value =>
              value === watch("new_password")
                ? true
                : "This password doesn't match"
          }
        })}
        error={errors?.password_confirm?.message}
      />
    </Fragment>
  );
};

export default ChangePasswordForm;

import React, { Fragment } from "react";

import { TextField } from "components/TextField";
import { Typography } from "components/Typography";

export const CodeConfirmationForm = ({ register, errors }) => (
  <Fragment>
    <Typography color="textSecondary">
      Type the code we sent to your email in the form below.
    </Typography>
    <TextField
      name="code"
      placeholder="Code"
      error={errors?.code?.message}
      inputRef={register({ required: "Code required" })}
    />
  </Fragment>
);

export default CodeConfirmationForm;

import React from "react";

import Label from "components/TextField/Label";
import { Typography } from "components/Typography";

import styles from "./ListItem.module.css";

export const ListItem = ({ label, text }) => (
  <div className={styles.list_item}>
    <Label>{label}</Label>
    <Typography>{text}</Typography>
  </div>
);

export default ListItem;

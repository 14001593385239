import React from "react";

import styles from "./Loader.module.css";

export const LoaderWrapper = props => (
  <div className={styles.wrapper} {...props} />
);

export const Loader = ({ size = 24, thickness = 3, color = "#5ab63c" }) => (
  <div
    className={styles.loader}
    style={{
      width: size,
      height: size,
      borderColor: color,
      borderWidth: thickness
    }}
  />
);

export default Loader;

import React from "react";
import cx from "classnames";

import buttonStyles from "./Button.module.css";
import styles from "./IconButton.module.css";

export const IconButton = ({
  color = "default",
  component: Component = "button",
  className,
  ...other
}) => (
  <Component
    className={cx(
      styles.icon_button,
      buttonStyles["button_color_" + color],
      className
    )}
    {...other}
  />
);

import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import "./index.css";

import AuthProvider from "./auth/AuthProvider";
import Auth from "./auth/Auth";
import Profile from "./profile/profile";
import Users from "./users/Users";
import Company from "./company/company";
import Customers from "./customers/Customers";
import Permission from "containers/Permission";

const Redirector = () => (
  <Fragment>
    <Permission required={["manager"]}>
      <Route path="/company" component={Company} />
      <Redirect from="/" to="/company" />
      <Redirect to="/company" />
    </Permission>
    <Permission required={["admin"]}>
      <Route path="/customers" component={Customers} />
      <Redirect from="/" to="/customers" />
      <Redirect to="/customers" />
    </Permission>
    <Permission required={["technician"]}>
      <Redirect from="/" to="/profile" />
      <Redirect to="/profile" />
    </Permission>
  </Fragment>
);

function App() {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/" component={Redirector} />
        <Route path="/login" component={Auth} />
        <Route path="/profile" component={Profile} />
        <Route path="/users" component={Users} />
        <Route path="/company" component={Company} />
        <Route path="/customers" component={Customers} />
        <Redirect to="/" />
      </Switch>
    </AuthProvider>
  );
}

export default App;

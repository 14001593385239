import React from "react";
import cx from "classnames";

import styles from "./Table.module.css";
import Loader from "components/Loader/Loader";
import { NavLink } from "react-router-dom";

export const Table = ({ fab, ...other }) => (
  <div className={styles.table_relative}>
    <div className={styles.table_wrapper}>
      <table className={styles.table} {...other} />
    </div>
    {fab}
  </div>
);

export const TableHeader = props => (
  <thead className={styles.table_header}>
    <TableRow {...props} />
  </thead>
);

export const TableHeaderCell = props => (
  <TableCell component="th" className={styles.table_header_cell} {...props} />
);

export const TableBody = props => (
  <tbody className={styles.table_body} {...props} />
);

export const TableRow = ({ to, children, ...other }) => {
  const body = to
    ? React.Children.toArray(children).map(child =>
        React.cloneElement(child, { to })
      )
    : children;

  return (
    <tr className={styles.table_row} {...other}>
      {body}
    </tr>
  );
};

export const TableCell = ({ to, className, children, ...other }) => {
  const body = to ? (
    <NavLink
      className={cx(styles.table_cell_padding, styles.table_cell_link)}
      to={to}
    >
      {children}
    </NavLink>
  ) : (
    children
  );
  return (
    <td
      className={cx(
        {
          [styles.table_cell]: true,
          [styles.table_cell_padding]: to == null
        },
        className
      )}
      {...other}
    >
      {body}
    </td>
  );
};

export const TableLoader = () => (
  <TableRow>
    <TableCell colSpan={999}>
      <Loader />
    </TableCell>
  </TableRow>
);

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { push } from "connected-react-router";
import useForm from "react-hook-form";

import { Paper, Toolbar, Separator } from "containers";
import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";
import { Form } from "components/Form";
import { Loader } from "components";

import { ReactComponent as BackIcon } from "static/icons/back.svg";

import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import ChangePasswordForm from "./change_password_form";
import Content from "containers/Content";

export const ProfileChangePassword = ({ location, push }) => {
  const {
    handleSubmit,
    watch,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = values => {
    return api
      .post("/auth/password_recovery/reset_password/", {
        ...location.state,
        ...values
      })
      .then(() =>
        push({ ...location, pathname: "/profile/change_password_success" })
      )
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/profile/code_confirmation">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">Changing password</Typography>
      </Toolbar>

      <Paper>
        <Content>
          <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
            <ChangePasswordForm
              errors={errors}
              register={register}
              watch={watch}
            />
            <Toolbar margin={false}>
              <Separator />
              <Button disabled={isSubmitting} primary>
                {isSubmitting ? <Loader /> : "Change"}
              </Button>
            </Toolbar>
          </Form>
        </Content>
      </Paper>
    </Fragment>
  );
};

export default connect(null, dispatch => ({
  push: location => dispatch(push(location))
}))(ProfileChangePassword);

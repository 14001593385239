import React from "react";
import Popup from "reactjs-popup";

import "./Popover.css";

export const Popover = ({ contentStyle, ...other }) => (
  <Popup
    arrow={false}
    contentStyle={{ padding: 0, ...contentStyle }}
    {...other}
  ></Popup>
);

export default Popover;

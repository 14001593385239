import React, { Fragment, useEffect } from "react";
import useAxios from "axios-hooks";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Paper, Toolbar, Separator } from "containers";

import { selectors } from "store/auth";
import { Typography } from "components/Typography";
import { IconButton, Button } from "components/Button";

import { ReactComponent as BackIcon } from "static/icons/back.svg";
import useForm from "react-hook-form";
import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import { Form } from "components/Form";
import { Loader } from "components";
import CustomerEditForm, { world } from "app/customers/edit/form";
import { push } from "connected-react-router";

export const CompanyEdit = ({ me, push }) => {
  const [{ data }] = useAxios(`/customers/${me.customer_account}/`, {
    useCache: false
  });

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: data
  });

  useEffect(() => {
    if (data == null) return;

    const { country, ...other } = data;
    Object.entries({
      country: world.find(x => x.alpha3 === country),
      ...other
    }).forEach(([key, value]) => setValue(key, value));
  }, [data, setValue]);

  const onSubmit = ({ country, ...other }) => {
    return api
      .patch(`/customers/${me.customer_account}/`, {
        country: country.alpha3,
        ...other
      })
      .then(() => push(`/company/`))
      .catch(error => errorToForm(error).forEach(x => setError(...x)));
  };

  return (
    <Fragment>
      <Toolbar>
        <IconButton component={NavLink} to="/company">
          <BackIcon />
        </IconButton>
        <Typography variant="h5">Company editing</Typography>
      </Toolbar>
      <Paper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CustomerEditForm
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
          />
          <Toolbar margin={false}>
            <Separator />
            <Button disabled={isSubmitting} primary>
              {isSubmitting ? <Loader /> : "Save"}
            </Button>
          </Toolbar>
        </Form>
      </Paper>
    </Fragment>
  );
};

export default connect(
  state => ({
    me: selectors.me(state)
  }),
  dispatch => ({
    push: location => dispatch(push(location))
  })
)(CompanyEdit);

import React from "react";
import { Fragment } from "react";

import { ListItem } from "components/List";
import { world } from "../edit/form";
import { Group, Column } from "components/Group/Group";

export const CustomerView = ({ customer }) => (
  <Fragment>
    <Group>
      <Column>
        <ListItem label={"Company name"} text={customer.company_name} />
        <ListItem label={"Phone number"} text={customer.phone_number} />
        <ListItem label={"Address"} text={customer.address_line_one} />
        <ListItem label={"Address 2"} text={customer.address_line_two} />
        <ListItem label={"City"} text={customer.city} />
        <ListItem label={"Zip"} text={customer.zip} />
        <ListItem
          label={"Country"}
          text={world.find(x => x.alpha3 === customer.country).name}
        />
      </Column>
      {customer.primary_user && (
        <Column>
          <ListItem
            label={"Contact person"}
            text={`${customer.primary_user.first_name} ${customer.primary_user.last_name}`}
          />
          <ListItem label={"Email"} text={customer.primary_user.email} />
        </Column>
      )}
    </Group>
  </Fragment>
);

export default CustomerView;

import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "components/Typography";

import styles from "./NavBar.module.css";
import Permission from "containers/Permission";

const NavBar = () => (
  <div className={styles.nav_bar}>
    <Permission required={["admin"]}>
      <Typography
        activeClassName={styles.nav_item_active}
        className={styles.nav_item}
        component={NavLink}
        to="/customers"
        variant="h5"
      >
        Customers
      </Typography>
    </Permission>
    <Permission required={["manager"]}>
      <Typography
        activeClassName={styles.nav_item_active}
        className={styles.nav_item}
        component={NavLink}
        to="/company"
        variant="h5"
      >
        Company
      </Typography>
    </Permission>
    <Permission required={["admin", "manager"]}>
      <Typography
        activeClassName={styles.nav_item_active}
        className={styles.nav_item}
        component={NavLink}
        to="/users"
        variant="h5"
      >
        Users
      </Typography>
    </Permission>
  </div>
);

export default NavBar;

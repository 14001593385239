import { routerMiddleware } from "connected-react-router";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import createReducer from "./reducer";

export default history =>
  configureStore({
    reducer: createReducer(history),
    middleware: [...getDefaultMiddleware(), routerMiddleware(history)]
  });

import React, { Fragment } from "react";
import { TextField } from "components/TextField";
import HookSelect from "components/TextField/Select/HookSelect";
import CustomerSelect from "app/customers/select";
import Permission from "containers/Permission";
import HookHiddenField from "components/TextField/HookHiddenInput";

export const roles = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Business manager" },
  { value: "technician", label: "Technician" }
];

export const UserEditForm = ({ me, register, watch, setValue, errors }) => {
  const availableRoles = me.groups.includes("admin")
    ? roles
    : me.groups.includes("manager")
    ? roles.filter(x => x.value !== "admin")
    : [];

  return (
    <Fragment>
      <TextField
        name="first_name"
        label="First name"
        inputRef={register({
          required: "First name required"
        })}
        error={errors?.first_name?.message}
      />
      <TextField
        name="last_name"
        label="Last name"
        inputRef={register({
          required: "Last name required"
        })}
        error={errors?.last_name?.message}
      />
      <TextField
        name="username"
        label="User name"
        inputRef={register({
          required: "Username required"
        })}
        error={errors?.username?.message}
      />
      <TextField
        name="email"
        label="Email"
        type="email"
        inputRef={register({
          required: "Email required"
        })}
        error={errors?.email?.message}
      />
      <Permission required={["admin", "manager"]}>
        <HookSelect
          menuPlacement="auto"
          name="groups"
          label="Roles"
          register={register({
            required: "Role required"
          })}
          watch={watch}
          setValue={setValue}
          options={availableRoles}
          error={errors?.groups?.message}
        />
      </Permission>
      <Permission
        required={["admin"]}
        fallback={
          <HookHiddenField name="customer_account" register={register} />
        }
      >
        <CustomerSelect
          name="customer_account"
          label="Customer"
          register={register}
          watch={watch}
          setValue={setValue}
          error={errors?.customer_account?.message}
        />
      </Permission>
    </Fragment>
  );
};

export default UserEditForm;

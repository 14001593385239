import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import useForm from "react-hook-form";

import api from "core/axios";
import { errorToForm } from "core/apiUtils";

import Form from "components/Form/Form";
import { TextField, PasswordField } from "components/TextField";
import { Button } from "components/Button";
import { FormTitle, Typography } from "components/Typography";
import Loader from "components/Loader/Loader";
import { connect } from "react-redux";

import { actions } from "store/auth";

import styles from "./Auth.module.css";

const SignIn = ({ login }) => {
  const {
    handleSubmit,
    register,
    errors,
    setError,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = values =>
    api
      .post("/auth/login/", values)
      .then(({ data }) => login(data))
      .catch(error => errorToForm(error).forEach(x => setError(...x)));

  return (
    <Fragment>
      <div className={styles.mobile}>
        <img alt="Company logo" src="/img/logo1.png" width="297" height="90" />
        <Typography variant="h5" style={{ justifyContent: "center" }}>
          OpCheck
        </Typography>
        <Typography noWrap variant="h6" style={{ justifyContent: "center" }}>
          Web management site
        </Typography>
        <div style={{ height: 40 }} />
      </div>

      <FormTitle align="center">Sign In</FormTitle>

      <div style={{ height: 60 }} />
      <Form error={errors?.form?.message} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          placeholder="Login"
          id="username"
          name="username"
          inputRef={register({
            required: "Login required"
          })}
          error={errors?.username?.message}
        />
        <PasswordField
          placeholder="Password"
          id="password"
          name="password"
          inputRef={register({
            required: "Password required"
          })}
          error={errors?.password?.message}
        />
        <Button primary fullWidth disabled={isSubmitting} type="submit">
          {isSubmitting ? <Loader /> : "Sign In"}
        </Button>
      </Form>
      <Button
        component={NavLink}
        to={location => ({ ...location, pathname: "/login/recovery" })}
      >
        Forgot your password?
      </Button>
    </Fragment>
  );
};

export default connect(null, dispatch => ({
  login: data => dispatch(actions.login(data))
}))(SignIn);

import React from "react";
import cx from "classnames";

import styles from "./Containers.module.css";

const Content = ({ className, ...other }) => (
  <div className={cx(styles.content, className)} {...other} />
);

export default Content;

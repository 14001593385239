import React, { Fragment } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import useAxios from "axios-hooks";
import { Paper, Toolbar } from "containers";

import CustomerView from "app/customers/view/form";

import { selectors } from "store/auth";

import { Fab } from "components/Button";

import { ReactComponent as EditIcon } from "static/icons/edit.svg";
import NavBar from "app/navigation/NavBar";

export const ProfileView = ({ me }) => {
  const [{ data }] = useAxios(`/customers/${me.customer_account}/`, {
    useCache: false
  });

  return (
    <Fragment>
      <Toolbar>
        <NavBar />
      </Toolbar>
      <Paper>
        {data && <CustomerView customer={data} />}
        <Fab component={NavLink} to="/company/edit">
          <EditIcon />
        </Fab>
      </Paper>
    </Fragment>
  );
};

export default connect(state => ({
  me: selectors.me(state)
}))(ProfileView);

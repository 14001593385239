import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

import { Button } from "components/Button";
import { FormTitle, Typography } from "components/Typography";

const RecoverySuccess = () => (
  <Fragment>
    <FormTitle align="center">Password recovery</FormTitle>
    <div style={{ height: 60 }} />
    <Typography color="textSecondary">Password recovery success.</Typography>
    <div style={{ height: 10 }} />
    <Button
      primary
      component={NavLink}
      to={location => ({ ...location, pathname: "/login" })}
    >
      Back
    </Button>
  </Fragment>
);

export default RecoverySuccess;

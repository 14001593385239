import React from "react";
import cx from "classnames";

import styles from "./Containers.module.css";

export const Paper = ({ className, ...other }) => (
  <div className={cx(styles.paper, className)} {...other} />
);

export default Paper;

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import createStore from "store";

import "./core/idleTimer";

import App from "./app/App";

export const history = createBrowserHistory();
export const store = createStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

import React from "react";
import cx from "classnames";

import styles from "./MenuItem.module.css";

import Button from "./Button";

export const MenuItem = ({ className, ...other }) => (
  <Button
    color="default"
    fullWidth
    className={cx(styles.menu_item, className)}
    {...other}
  />
);

export default MenuItem;

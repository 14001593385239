import React from "react";
import cx from "classnames";

import styles from "./Containers.module.css";

export const Toolbar = ({
  component: Component = "div",
  margin = true,
  className,
  ...other
}) => (
  <Component
    className={cx(
      styles.toolbar,
      { [styles.toolbar_margin]: margin },
      className
    )}
    {...other}
  />
);

export default Toolbar;
